$(document).ready(function () {
    // Menu
    let body = $("body");
    let mobileMenu = $("button.menu-icon");
    let sideNav = $(".side-nav");

    function menuToggle(e) {
        e.preventDefault();
        mobileMenu.toggleClass("menu-active");
        sideNav.toggleClass("side-nav-toggle");
        body.toggleClass("overflow-hidden");
    }

    mobileMenu.on("click", function (e) {
        menuToggle(e);
    });
    $(".nav-link, .scroll-btn").on("click", function (e) {
        if ($(window).width() < 992 && mobileMenu.hasClass("menu-active")) {
            menuToggle(e);
        }
    });

    // Menu highlighting
    function highlightMenuItem() {
        let scrollPosition = $(window).scrollTop();
        $('section').each(function() {
            let top = $(this).offset().top - 200;
            let bottom = top + $(this).outerHeight();
            if (scrollPosition >= top && scrollPosition <= bottom) {
                let sectionId = $(this).attr('id');
                $('.menu-item').find('a[href="#' + sectionId + '"]').removeClass('active');
                $('.menu-item').find('a[href="#' + sectionId + '"]').addClass('active');
            }
            else {
                let sectionId = $(this).attr('id');
                $('.menu-item').find('a[href="#' + sectionId + '"]').removeClass('active');
            }
        });
    }
    $(window).scroll(function() {
        highlightMenuItem();
    });

    // Scrolling
    $("a[href*='#']:not([href='#']), .button > .btn").click(function () {
        if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
            let target = $(this.hash);
            target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
            if (target.length) {
                $("html, body").animate({
                    scrollTop: (target.offset().top - 130)
                });
                return false;
            }
        }
    });

    // Form
    let quoteForm = $("#quote-form");

    quoteForm.submit(function (event) {
        event.preventDefault();
        event.stopPropagation();

        // Add validation styling
        $(this).addClass("was-validated");
        let statusMessage = $("#status-message");
        statusMessage.removeClass("d-none");

        // If validation passes
        if (this.checkValidity() === true) {
            statusMessage.html("Sending request...").removeClass("alert-danger").addClass("alert-info");

            // reCAPTCHA authorisation
            grecaptcha.ready(function () {
                grecaptcha.execute("6Lf4CRApAAAAAHoXRWwwwahPXLraMdlrxLtAL1jT", {action: "contact"}).then(function (token) {
                    let recaptchaResponse = document.getElementById("recaptcha-response");
                    recaptchaResponse.value = token;

                    // Make the ajax call
                    $.ajax({
                        url: "mail.php",
                        type: "POST",
                        data: quoteForm.serialize(),
                        success: function (response) {
                            $("#submit").addClass("disabled");
                            statusMessage.html(response).removeClass("alert-info alert-danger").addClass("alert-success");
                        },
                        error: function (response) {
                            statusMessage.html(response).removeClass("alert-info alert-danger").addClass("alert-danger");
                        }
                    });
                });
            });
        } else {
            statusMessage.html("Please check the fields and try again.").removeClass("alert-info").addClass("alert-danger");
        }

        // Scroll to top once submitted
        $("html, body").animate({
            scrollTop: (quoteForm.offset().top - ($(".navbar").height() + 30)),
        }, 250);
    });
    $(".form-check").on("click", "input:checkbox", function () {
        // Ensure at least one checkbox is checked
        let checkbox = $(".form-check input[type='checkbox']");
        let checked = $(".form-check input[type='checkbox']:checked");
        if (checked.length < 1) {
            checkbox.prop("required", true);
        } else {
            checkbox.prop("required", false);
        }
    });

    // Banner carousel
    const bannerCarousel = $(".banner-carousel");
    const bannerCarouselSlides = bannerCarousel.find(".banner-carousel-item");
    const bannerCarouselDots = bannerCarousel.find(".banner-carousel-dots .banner-carousel-dot");
    const bannerCarouselDotsAlt = bannerCarousel.find(".banner-carousel-dots .banner-carousel-dot-alt");
    let bannerCarouselIndex = 0;

    // Set active banner slide
    function showImage(index) {
        bannerCarouselSlides.css("opacity", "0");
        bannerCarouselSlides.eq(index).css({
            "opacity": "1",
            "visibility": "visible"
        });
    }

    // Set active banner dot
    function activeDot(index) {
        bannerCarouselDots.removeClass("active").removeAttr("aria-current").prop("disabled", false);
        bannerCarouselDots.eq(index).addClass("active").attr("aria-current", "true").prop("disabled", true);

        // Accessibility dot fix for mobile
        bannerCarouselDotsAlt.removeClass("active").removeAttr("aria-current").prop("disabled", false);
        bannerCarouselDotsAlt.eq(index).addClass("active").attr("aria-current", "true").prop("disabled", true);
    }

    // Banner navigation
    bannerCarousel.find(".banner-carousel-prev").on("click", function () {
        bannerCarouselIndex = (bannerCarouselIndex - 1 + bannerCarouselSlides.length) % bannerCarouselSlides.length;
        showImage(bannerCarouselIndex);
        activeDot(bannerCarouselIndex);
    });
    bannerCarousel.find(".banner-carousel-next").on("click", function () {
        bannerCarouselIndex = (bannerCarouselIndex + 1) % bannerCarouselSlides.length;
        showImage(bannerCarouselIndex);
        activeDot(bannerCarouselIndex);
    });
    bannerCarouselDots.each(function (index) {
        $(this).on("click", function () {
            showImage(index);
            activeDot(index);
            bannerCarouselIndex = index;
        });
    });

    // Start banner carousel
    showImage(bannerCarouselIndex);
    activeDot(bannerCarouselIndex);

    // Cookie popup
    const cookiePopup = $(".cookie-popup");
    const acceptCookiesBtn = $(".cookie-accept");

    function setCookie(name, value, days) {
        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = name + "=" + value + ";expires=" + expires.toUTCString();
    }

    function getCookie(name) {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + "=")) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    }

    const cookiesAccepted = getCookie("cookie-policy") === "true";

    if (!cookiesAccepted) {
        cookiePopup.show();
    } else {
        loadOtherCookies();
    }

    function loadOtherCookies() {
        // Load other cookies code
    }

    acceptCookiesBtn.on("click", function() {
        setCookie("cookie-policy", "true", 30);
        cookiePopup.hide();
        location.reload();
    });
});
